import React, { useMemo, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import HomeContainer from "../../containers/HomeContainer.js";
import { set } from "lodash";

/**
 * @returns {Route} a route component which checks authorization on render or redirects to login
 */
const AuthRoute = ({ component: Component, ...rest }) => {
  const authOk = localStorage.getItem("usertoken");

  const ChildComponent = useMemo(() => {
    if (!authOk) {
      return (props) => {
        const { location } = props;
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        );
      };
    }
    return Component;
  }, [Component, authOk]);

  const [pageHearder, setPageHeader] = useState("Home");
  const [hasSearch, setHasSearch] = useState(false);

  const handlePageHeader = (header, val) => {
    setPageHeader(header);
    setHasSearch(val);
  };
  return (
    <Route
      {...rest}
      render={(props) => (
        <HomeContainer headerText={pageHearder} hasSearch={hasSearch}>
          <ChildComponent asignPageHeader={handlePageHeader} {...props} />
        </HomeContainer>
      )}
    />
  );
};

export default AuthRoute;
