import React from "react";

const NewChatIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_76_8171)">
        <path
          d="M7.33398 2.6665H2.66732C2.3137 2.6665 1.97456 2.80698 1.72451 3.05703C1.47446 3.30708 1.33398 3.64622 1.33398 3.99984V13.3332C1.33398 13.6868 1.47446 14.0259 1.72451 14.276C1.97456 14.526 2.3137 14.6665 2.66732 14.6665H12.0007C12.3543 14.6665 12.6934 14.526 12.9435 14.276C13.1935 14.0259 13.334 13.6868 13.334 13.3332V8.6665"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.334 1.66665C12.5992 1.40144 12.9589 1.25244 13.334 1.25244C13.7091 1.25244 14.0688 1.40144 14.334 1.66665C14.5992 1.93187 14.7482 2.29158 14.7482 2.66665C14.7482 3.04173 14.5992 3.40144 14.334 3.66665L8.00065 9.99999L5.33398 10.6667L6.00065 7.99999L12.334 1.66665Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_76_8171">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default NewChatIcon;
