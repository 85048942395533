import { Box } from "@mui/system";
import React, { useState } from "react";

const TruncateText = ({ text, onClick, id, max = 23 }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const handleTextHoverd = () => {
    setIsTruncated(false);
  };

  const handleTextout = () => {
    setIsTruncated(true);
  };

  return (
    <Box
      sx={{
        fontSize: "14px",
        py: "16px",
        pl: "16px",
        fontFamily: "Open Sans",
        width: "100%",
      }}
      onMouseOver={handleTextHoverd}
      onMouseOut={handleTextout}
      onClick={() => {
        onClick(id);
      }}
    >
      {/* {sess.name} */}
      {isTruncated && text.length > max ? text.slice(0, max - 1) + "..." : text}
    </Box>
  );
};

export default TruncateText;
