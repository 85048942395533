import * as React from "react";
import Box from "@mui/material/Box";
import LeftSideBar from "../localcomponents/LeftSideBar";
import FloatingView from "../views/home/AutoPilotPage";

import Fab from "@mui/material/Fab";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const drawerWidth = 240;

const HomeContainer = ({ children, headerText, hasSearch }) => {
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const handleSearchClick = (val) => {
    setOpen(val);
  };

  const [isCopilotOpen, setIsCopilotOpen] = React.useState(false);
  const getCopilotState = (val) => {
    setIsCopilotOpen(val);
  };

  const getCopilotExpandedState = (val) => {
    setOpen(val);
  };

  let [expanded, setExpanded] = React.useState(false);

  const handleExpand = (val) => {
    setExpanded(val);
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <LeftSideBar
          open={open}
          onToggle={handleExpand}
          handleExpandBar={handleSearchClick}
        />

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            minHeight: "100vh",
          }}
        >
          <Box
            sx={{
              width: isCopilotOpen ? `calc(100% - ${385}px)` : "100%",
              display: "flex",
              alignItems: "start",
              position: "relative",
            }}
            className="customScrollerlook"
          >
            <Box
              sx={{
                position: "absolute",
                left: "-17.5px",
                top: "85px",
                zIndex: 1300,
              }}
            >
              <Box
                sx={{
                  p: "1px",
                  background: "#1E5267",
                  borderRadius: "50%",
                }}
              >
                <Fab
                  onClick={handleDrawerOpen}
                  aria-label="back"
                  style={{
                    background: "#fff",
                    width: "36px",
                    height: "32px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "none",
                  }}
                >
                  {open ? (
                    <ArrowForwardIosIcon
                      sx={{ ml: "3px", fontSize: "15px", color: "#1E5267" }}
                    />
                  ) : (
                    <ArrowBackIosIcon
                      sx={{ ml: "5px", fontSize: "15px", color: "#1E5267" }}
                    />
                  )}
                </Fab>
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                height: "100vh",
                overflowY: "auto",
                backgroundColor: "#F9FAFB",
              }}
            >
              {children}
            </Box>

            <Box
              sx={{
                position: "fixed",
                right: 0,
                top: 0,
                height: "50px",
              }}
            >
              <FloatingView
                expanded={expanded}
                onExpandClicked={handleExpand}
                onExpand={getCopilotExpandedState}
                onToggle={getCopilotState}
                leftSidebarState={open}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HomeContainer;
