import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { TextField } from "@mui/material";

const useStyles = makeStyles(({}) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      display: "flex",
      alignItems: "end",
      FontFamily: "Open Sans",
      fontWeight: "500",
      borderRadius: "7px",
      fontSize: "15.5px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      // borderColor: "#ff6413",
      border: "1px solid #eef2f6",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      // borderColor: "#ff6413",
      border: "1px solid #eef2f6",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ff6413",
      border: "1px solid #eef2f6",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  input: {
    borderRadius: "20px",
  },
}));

const DynamicInput = ({ val, onChange, width, label, isTyping, onTyping }) => {
  const classes = useStyles();

  const handleChange = (e) => {
    onChange(label, e.target.value);

    if (!isTyping) {
      onTyping(true);
    }
  };
  return (
    <TextField
      id="outlined-basic"
      variant="outlined"
      size="small"
      sx={{ width: width }}
      className={classes.root}
      defaultValue={val}
      onChange={handleChange}
    />
  );
};

DynamicInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  rows: PropTypes.number,
  borderRD: PropTypes.bool,
};

export default DynamicInput;
