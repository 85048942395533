import React from "react";
import { Card, CardMedia, Box, Typography } from "@mui/material";
import DisplayText from "./DisplayText";
import recim from "../statics/images/rec.png";
import { useHistory } from "react-router-dom";

const TestItem = ({
  course_data,
  pti,
  fetchowndata = {},
  onClick = () => {},
}) => {
  const history = useHistory();

  return (
    <Card
      sx={{
        cursor: "pointer",
        boxShadow: " 0px 2px 8px 0px rgba(0, 0, 0, 0.10)",
        // ":hover": { boxShadow: "0px 14px 14px rgba(0, 0, 0, 0.05)" },
        position: "relative",
        borderRadius: "10px",
        // p: 2,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      onClick={() => {
        onClick();
        history.push({
          pathname:
            "/app/workflows/" + course_data?.public_workflow_id + "/diag",
          state: { content: course_data?.content, ptid: pti?.patid },
        });
      }}
    >
      <Box
        sx={{
          width: "100%",
          borderRadius: "10px 10px 0 0 ",
          overflow: "hidden",
          mb: 0.5,
        }}
      >
        <CardMedia
          component="img"
          height="160"
          src={fetchowndata.image_url}
          alt="workflow img"
        />
      </Box>
      <Box
        sx={{
          p: 2,
          m: 0,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            borderRadius: "10px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "end",
              width: "100%",
              justifyContent: "space-between",
              mb: "10px",
            }}
          >
            <Box
              sx={{
                height: "35px",
                width: "75px",
                display: "flex",
                alignItems: "center",
                backgroundImage: `url(${
                  fetchowndata?.organization_logo_url + ""
                })`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "left",
                backgroundPositionY: "center",
              }}
            >
              {/* <img
                src={fetchowndata?.organization_logo_url + ""}
                width={"50px"}
              /> */}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <DisplayText
                content={fetchowndata?.organization_name + ""}
                fontFamily={"Open Sans"}
                size={"sm"}
                fontWeight={"600"}
                color={"#000"}
              />
            </Box>
          </Box>

          <Box sx={{ overflowY: "clip" }}>
            <DisplayText
              content={course_data?.content?.name}
              fontFamily={"Open Sans"}
              size={"md"}
              fontWeight={"600"}
              color={"#000"}
            />
          </Box>
          <Box
            sx={{
              mb: 1,
              minHeight: "50px",
              overflowx: "auto",
              gap: "8px",
            }}
          >
            <Typography
              sx={{
                textOverflow: "ellipsis",
                fontFamily: "Open Sans",
                fontSize: "16px",
                fontWeight: "700",
                color: "#000",
              }}
            >
              {fetchowndata?.name}
            </Typography>
            <Typography
              sx={{
                textOverflow: "ellipsis",
                color: "#475467",
                fontSize: "14px",
                fontFamily: "Open Sans",
              }}
            >
              {fetchowndata?.description}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "start" }}>
            <Box
              sx={{
                background: "#FB6514",
                color: "#fff",
                fontSize: "15px",
                textAlign: "center",
                borderRadius: "8px",
                cursor: "pointer",
                height: "36px",
                px: "40px",
                width: "100%",
                mt: 1.5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Order Now
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default TestItem;
