import React from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import "../index.css";

function DisplayText({
  content = "",
  size = "sm",
  align,
  color = "#000",
  fontWeight = "400",
  style,
  ...props
}) {
  const fontForSize = (type) => {
    if (type === "3xl") return 72;
    if (type === "2xl") return 40;
    if (type === "xl") return 24;
    if (type === "lg") return 19;
    if (type === "md") return 16;
    if (type === "sm") return 14;
    if (type === "xs") return 12.5;

    return type;
  };

  return (
    <Typography
      fontSize={fontForSize(size)}
      textAlign={align}
      color={color}
      fontWeight={fontWeight}
      fontFamily={"Open Sans"}
      style={style}
      {...props}
    >
      {content}
    </Typography>
  );
}

DisplayText.propTypes = {
  content: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md", "lg", "xl", "2xl", "3xl"]),
  color: PropTypes.string,
  align: PropTypes.string,
  fontWeight: PropTypes.string,
  style: PropTypes.any,
};

export default DisplayText;
