import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";

import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import ContentParser from "../../localcomponents/ContentParser.js";
import TextInputComponent from "../../localcomponents/TextInput";
import { useHistory } from "react-router";
import DisplayText from "../../localcomponents/DisplayText";

import {
  getCopilotRequest,
  getChatSessions,
  getSessionMessages,
  updateSessionName,
} from "../../localservices/auth.service";
import DotLoading from "../../components/DotLoading";
import TruncateText from "../components/TruncateText";

import HttpService from "../../localservices/http.service";
import CopilotSuggestionCard from "../../localcomponents/CopilotSuggestionCard";
import CopilotSuggestionCardMini from "../../localcomponents/CopilotSuggestionCardMini";
import RotateRightRoundedIcon from "@mui/icons-material/RotateRightRounded";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const duplogo = require("../../statics/images/duplogo.png");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const mainlogo = require("../../statics/images/logotwo.png");

import ExpandIcon from "../../localcomponents/Icons/Expand";
import ColapseIcon from "../../localcomponents/Icons/Colapse";
import NewChatIcon from "../../localcomponents/Icons/NewChat";
import SendIcon from "../../localcomponents/Icons/Send";
import CloseIcon from "../../localcomponents/Icons/Close";

import SessionsColapseIcon from "../../localcomponents/Icons/SessionsColapse";
import MenuIcon from "../../localcomponents/Icons/Menu";
import SearchIcon from "../../localcomponents/Icons/Search";

import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import "../../index.css";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "@mui/styles";
import DynamicInput from "../../localcomponents/DynamicInput";
import TestItem from "../../localcomponents/TestItem";

import { suggestionUrl, xaiBaseURL } from "../../localservices/url.service";
import { fetchPatientsResource } from "../../localservices/patient.service";
import DashboardTable from "../components/DashboardTable";
import MeetingTable from "../components/MeetingTable";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root": {
      display: "flex",
      alignItems: "end",
      FontFamily: "Open Sans",
      fontWeight: "500",
      borderRadius: "8px",
      fontSize: "15.5px",
      padding: " 12px 6px 12px 20px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#EAECF0",
      border: "1px solid #EAECF0",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#EAECF0",
      border: "1px solid #EAECF0",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#EAECF0",
      border: "1px solid #EAECF0",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  input: {
    borderRadius: "8px",
  },
}));

const AutoPilotPage = ({
  onToggle,
  // onExpand,
  leftSidebarState,
  expanded,
  onExpandClicked,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [prompt, setPrompt] = useState(null);
  const [selectedSessions, setSelectedSessions] = useState("");
  const [sessions, setSessions] = useState([]);
  const [miniSession, setMiniSession] = useState(false);
  // const [onboarding, setOnboarding] = useState(false);
  const [tempSessionName, setTempSessionName] = useState({ id: "", name: "" });

  const [loading, setLoading] = useState(false);
  // const [expanded, onExpandClicked] = useState(false);

  const [chat, setChat] = useState([]);
  const messagesEndRef = useRef(null);
  const promptRef = useRef(null);
  const messagesEndRefSm = useRef(null);
  const promptRefSm = useRef(null);

  // const onboardingdata = [
  //   {
  //     step: 1,
  //     name: "add a new patient",
  //     path: "/app/patients/new",
  //     intent: "addpatient",
  //   },
  //   {
  //     step: 2,
  //     name: "create an appointment for a patient",
  //     path: "/app/newAppointment",
  //     intent: "addappointment",
  //   },
  //   {
  //     step: 3,
  //     name: "add an encounter for appointment",
  //     path: "/app/appointments",
  //     intent: "addencounter",
  //   },
  //   {
  //     step: 4,
  //     name: "create task for an encounter",
  //     path: "/app/encounter",
  //     intent: "addtask",
  //   },
  //   {
  //     step: 5,
  //     name: "create a new sample",
  //     path: "/app/samples",
  //     intent: "addsamples",
  //   },
  //   // {step: 6, name:"run diagnostics for a patient", path:"/app/patients", intent:"rundiagnostics"},
  // ];

  const [rows, setRows] = useState(true);

  const inputFieldRef = useRef<HTMLInputElement>(null);

  const [showSuggestion, setShowSuggestion] = useState(true);
  const [sessionId, setSessionId] = useState("");

  const [allPatients, setAllPatients] = useState([]);
  const [pworkflow, setPWorkflow] = useState([]);
  const [patientsList, setPatientsList] = useState([]);
  const [appointmentsList, setAppointmentList] = useState([]);
  const [tasksList, setTasksList] = useState([]);
  const [meetingList, setMeetingList] = useState([]);
  const [encounterList, setEncounterList] = useState([]);
  const [patientCreated, setPatientCreated] = useState([]);
  const [practitionersList, setPractitionersList] = useState([]);
  const [reportsList, setReportsList] = useState([]);

  const getPatient = (pid: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const nameo = allPatients?.filter((sit: any) => sit?.value == pid);
    if (nameo) return nameo[0]?.name || "-";
    return "-";
  };

  const handlePromptReq = (copilotState: number) => {
    setNewChatClicked(false);
    setShowSuggestion(false);
    setPrompt(null);
    setRows(true);
    const newchat = [...chat, { type: "req", question: prompt }];

    setLoading(true);
    setChat(newchat);

    const orgId = localStorage.getItem("orgid");

    const newChatData = {
      message: prompt !== null ? prompt : JSON.stringify(formList),
      org_id: orgId,
    };
    const exsistionChatData = {
      message: prompt !== null ? prompt : JSON.stringify(formList),
      session_id: sessionId,
      org_id: orgId,
    };

    getCopilotRequest(sessionId === "" ? newChatData : exsistionChatData)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((cres: any) => {
        if (cres?.data) {
          setSessionId(cres.data.session_id);
          if (cres?.data?.session_name) {
            setHeadTitle(cres?.data.session_name);
          }

          const newChatObj = {
            exerpt: cres.data?.gpt,
            youtube: cres.data?.youtube?.[0],
            image: cres.data?.images?.[0],
            instruction: cres?.data?.nav_url,
            composite: cres?.data?.composite,
            type: "res",
          };
          setChat([...newchat, newChatObj]);
          if (cres.data.composite?.blocks[0]?.type === "form") {
            setFormList(cres.data.composite?.blocks[0]);
          }
          if (
            cres.data?.composite?.blocks[0]?.type ===
            "diagnostics_workflow_list"
          ) {
            const data = cres?.data?.composite?.blocks[0]?.data?.response;
            if (data) {
              setPWorkflow(Array.isArray(data) ? data : []);
            }
          }

          if (cres.data?.composite?.blocks[0]?.type === "patient_list") {
            const data =
              cres?.data?.composite?.blocks[0]?.data?.response?.entry;
            if (data) {
              setPatientsList(Array.isArray(data) ? data : []);
            }
          }

          if (cres.data?.composite?.blocks[0]?.type === "appointment_list") {
            const data =
              cres?.data?.composite?.blocks[0]?.data?.response?.entry;
            if (data) {
              setAppointmentList(Array.isArray(data) ? data : []);
            }
          }

          if (cres.data?.composite?.blocks[0]?.type === "task_list") {
            const data =
              cres?.data?.composite?.blocks[0]?.data?.response?.entry;
            if (data) {
              setTasksList(Array.isArray(data) ? data : []);
            }
          }

          if (cres.data?.composite?.blocks[0]?.type === "meeting_list") {
            const data = cres?.data?.composite?.blocks[0]?.data?.response?.data;

            const groupDataByDate = () => {
              return data.reduce((acc, item) => {
                const date = new Date(
                  item.timestamp_start
                ).toLocaleDateString();
                const existingGroup = acc.find((group) => group.date === date);

                if (existingGroup) {
                  existingGroup.data.push({
                    id: item.id,
                    name: item.name,
                    note: item.note,
                    report_id: item.report_id,
                    participants: item.participants,
                    timestamp_start: item.timestamp_start,
                    timestamp_end: item.timestamp_end,
                    timestamp_created: item.timestamp_created,
                    user_id: item.user_id,
                    timestamp_reminder_sent: item.timestamp_reminder_sent,
                    url: item.url,
                    vtb_link: item.vtb_link,
                    report_link: item.report_link,
                  });
                } else {
                  acc.push({
                    date,
                    data: [
                      {
                        id: item.id,
                        name: item.name,
                        note: item.note,
                        report_id: item.report_id,
                        participants: item.participants,
                        timestamp_start: item.timestamp_start,
                        timestamp_end: item.timestamp_end,
                        timestamp_created: item.timestamp_created,
                        user_id: item.user_id,
                        timestamp_reminder_sent: item.timestamp_reminder_sent,
                        url: item.url,
                        vtb_link: item.vtb_link,
                        report_link: item.report_link,
                      },
                    ],
                  });
                }

                return acc;
              }, []);
            };
            const groupedData = groupDataByDate();

            setMeetingList(groupedData.reverse());
          }

          if (cres.data?.composite?.blocks[0]?.type === "encounter_list") {
            const data =
              cres?.data?.composite?.blocks[0]?.data?.response?.entry;
            if (data) {
              setEncounterList(Array.isArray(data) ? data : []);
            }
          }

          if (cres.data?.composite?.blocks[0]?.type === "new_patient") {
            const data = cres?.data?.composite?.blocks[0]?.data?.response;
            if (data) {
              setPatientCreated([data]);
            }
          }
          if (cres.data?.composite?.blocks[0]?.type === "pracitioner_list") {
            const data = cres.data?.composite?.blocks[0]?.data?.response?.entry;
            if (data) {
              setPractitionersList(Array.isArray(data) ? data : []);
            }
          }

          if (
            cres.data?.composite?.blocks[0]?.type === "diagnostics_report_list"
          ) {
            const data = cres.data?.composite?.blocks[0]?.data?.response;
            if (data) {
              setReportsList(Array.isArray(data) ? data : []);
            }
          }

          setTimeout(() => {
            if (copilotState === 1) {
              messagesEndRefSm.current?.scrollIntoView({
                behavior: "smooth",
              });
            } else {
              messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
            }
          }, 200);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    setTimeout(() => {
      if (copilotState === 1) {
        promptRefSm.current?.scrollIntoView({ behavior: "smooth" });
      } else {
        promptRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }, 200);
  };

  const handleUpdateSessionName = (sindex: number) => {
    setLoading(true);
    updateSessionName(tempSessionName.id, tempSessionName.name)
      .then((sesrs) => {
        setLoading(false);
        const modifiedsess = sesrs.data.session;
        const prevss = [...sessions];
        prevss[sindex] = modifiedsess;
        setSessions(prevss);

        setTempSessionName({ id: "", name: "" });
      })
      .catch(() => {
        setLoading(false);
        setTempSessionName({ id: "", name: "" });
      });
  };

  // const handleSequencialChecklist = (
  //   checkedstep: number,
  //   currentst: number
  // ) => {
  //   if (checkedstep) {
  //     if (currentst - checkedstep == 0) return true;
  //   } else {
  //     if (currentst == 1) return true;
  //   }
  //   return false;
  // };

  const handleGoToPage = (navurl: string) => {
    const navvurl = navurl.trim();
    onExpandClicked(false);
    if (navurl == "/app/createsamples") {
      history.push("/app/samples", { state: { create: true } });
    } else {
      history.push(navvurl + "");
    }
  };

  const getSessionChat = (sessionid) => {
    setSessionId(sessionid);
    setSelectedSessions(sessionid);
    setLoading(true);
    getSessionMessages(1, sessionid)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((smr: any) => {
        setLoading(false);
        const sessionmsg = smr?.data?.messages?.reverse();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newchat = sessionmsg.map((smo: any) => {
          if (smo.content?.composite?.blocks[0]?.type === "form") {
            setFormList(smo.content?.composite?.blocks[0]);
          }
          if (
            smo.content?.composite?.blocks[0]?.type ===
            "diagnostics_workflow_list"
          ) {
            setPWorkflow(smo.content.composite?.blocks[0].data.response);
          }

          if (smo.content?.composite?.blocks[0]?.type === "patient_list") {
            const data =
              smo.content?.composite?.blocks[0]?.data?.response?.entry;
            if (data) {
              setPatientsList(Array.isArray(data) ? data : []);
            }
          }

          if (smo.content?.composite?.blocks[0]?.type === "appointment_list") {
            const data =
              smo.content?.composite?.blocks[0]?.data?.response?.entry;
            if (data) {
              setAppointmentList(Array.isArray(data) ? data : []);
            }
          }

          if (smo.content?.composite?.blocks[0]?.type === "task_list") {
            const data =
              smo.content?.composite?.blocks[0]?.data?.response?.entry;
            if (data) {
              setTasksList(Array.isArray(data) ? data : []);
            }
          }

          if (smo.content?.composite?.blocks[0]?.type === "meeting_list") {
            const data =
              smo.content?.composite?.blocks[0]?.data?.response?.data;

            const groupDataByDate = () => {
              return data.reduce((acc, item) => {
                const date = new Date(
                  item.timestamp_start
                ).toLocaleDateString();
                const existingGroup = acc.find((group) => group.date === date);

                if (existingGroup) {
                  existingGroup.data.push({
                    id: item.id,
                    name: item.name,
                    note: item.note,
                    report_id: item.report_id,
                    participants: item.participants,
                    timestamp_start: item.timestamp_start,
                    timestamp_end: item.timestamp_end,
                    timestamp_created: item.timestamp_created,
                    user_id: item.user_id,
                    timestamp_reminder_sent: item.timestamp_reminder_sent,
                    url: item.url,
                    vtb_link: item.vtb_link,
                    report_link: item.report_link,
                  });
                } else {
                  acc.push({
                    date,
                    data: [
                      {
                        id: item.id,
                        name: item.name,
                        note: item.note,
                        report_id: item.report_id,
                        participants: item.participants,
                        timestamp_start: item.timestamp_start,
                        timestamp_end: item.timestamp_end,
                        timestamp_created: item.timestamp_created,
                        user_id: item.user_id,
                        timestamp_reminder_sent: item.timestamp_reminder_sent,
                        url: item.url,
                        vtb_link: item.vtb_link,
                        report_link: item.report_link,
                      },
                    ],
                  });
                }

                return acc;
              }, []);
            };
            const groupedData = groupDataByDate();

            setMeetingList(groupedData.reverse());
          }

          if (smo.content?.composite?.blocks[0]?.type === "encounter_list") {
            const data =
              smo?.content?.composite?.blocks[0]?.data?.response?.entry;
            if (data) {
              setEncounterList(Array.isArray(data) ? data : []);
            }
          }

          if (smo.content?.composite?.blocks[0]?.type === "new_patient") {
            const data = smo.content?.composite?.blocks[0]?.data?.response;

            if (data) {
              setPatientCreated([data]);
            }
          }

          if (smo.content?.composite?.blocks[0]?.type === "pracitioner_list") {
            const data =
              smo?.content?.composite?.blocks[0]?.data?.response?.entry;
            if (data) {
              setPractitionersList(Array.isArray(data) ? data : []);
            }
          }

          if (
            smo.content?.composite?.blocks[0]?.type ===
            "diagnostics_report_list"
          ) {
            const data = smo?.content?.composite?.blocks[0]?.data?.response;
            if (data) {
              setReportsList(Array.isArray(data) ? data : []);
            }
          }
          const cobj = {
            exerpt: smo?.content?.gpt,
            youtube: smo?.content?.youtube?.[0],
            image: smo?.content?.images?.[0],
            instruction: smo?.content?.nav_url,
            type: smo?.role == "system" ? "res" : "req",
            question: smo?.content?.message,
            composite: smo?.content?.composite,
          };
          return cobj;
        });
        setChat(newchat);
        setTimeout(() => {
          promptRef.current?.scrollIntoView({ behavior: "smooth" });
          // promptRefSm.current?.scrollIntoView({ behavior: "smooth" });
        }, 200);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((e: any) => {
        setLoading(false);
        console.log("err", e);
      });
  };

  const handleMiniSession = (id: string) => {
    getSessionChat(id);
    setShowSuggestion(false);
    setChat([]);
  };

  const [allSessionsLoaded, setAllSessionsLoaded] = useState(false);
  const getAllSessions = () => {
    getChatSessions(1)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((sres: any) => {
        setSessions(sres.data?.sessions);
        setAllSessionsLoaded(true);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((e: any) => {
        console.log(e);
      })
      .finally(() => {
        setIsSearchingSession(false);
      });
  };
  useEffect(() => {
    getAllSessions();
  }, []);

  const openCopilot = () => {
    setOpen(true);
    onToggle(true);

    // if (expanded) {
    // onExpand(true);
    // }
  };
  const closeCopilot = () => {
    setOpen(false);
    onToggle(false);
  };

  const [expandRightBar, setExpandRightBar] = useState(true);
  // const toggleCopilotRightBar = () => {
  //   setExpandRightBar(!expandRightBar);
  // };

  const [suggestions, setSuggestions] = useState([]);

  const getSuggestionList = () => {
    HttpService.get(suggestionUrl, {})
      .then((res) => {
        setSuggestions(res.data?.content?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSuggestionMessage = (val) => {
    setPrompt(val);
  };
  const [newChatClicked, setNewChatClicked] = useState(false);
  console.log(newChatClicked);
  const [rotation, setRotation] = useState(0);
  const [addButtonsTransition, setAddButtonsTransition] = useState(expanded);

  const handleShuffleSuggestion = () => {
    showNext();
    setAddButtonsTransition(false);
    setTimeout(() => {
      setAddButtonsTransition(true);
    }, 100);
    setRotation((prevRotation) => prevRotation + 360);
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const [listIndex, setListIndex] = useState(0);
  const getListIndex = (val) => {
    setListIndex(val);
  };

  const showNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % listIndex);
  };

  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);

  const handleSuggestionClick = (childIndex, buttonIndex) => {
    if (
      selectedButtonIndex &&
      selectedButtonIndex.childIndex === childIndex &&
      selectedButtonIndex.buttonIndex === buttonIndex
    ) {
      // Clicked on an already selected button, deselect it
      setSelectedButtonIndex(null);
    } else {
      setSelectedButtonIndex({ childIndex, buttonIndex });
    }
  };

  const [formList, setFormList] = useState({
    data: {
      withHeadings: true,
      content: [],
    },
    prompt: "",
  });

  const [isTyping, setIsTyping] = useState(false);
  const getIsTyping = (val) => {
    setIsTyping(val);
  };

  const handleInputChange = (label, value) => {
    setFormList((prevFormList) => {
      if (prevFormList && prevFormList?.data && prevFormList?.data?.content) {
        const newContent = prevFormList?.data?.content.map((item) => {
          if (item && item.length === 3 && item[0] === label) {
            return [item[0], item[1], value];
          }
          return item;
        });

        return {
          ...prevFormList,
          data: {
            ...prevFormList.data,
            content: newContent,
          },
        };
      }

      // Handle the case where the structure is not as expected
      return prevFormList;
    });
  };

  const [headTitle, setHeadTitle] = useState("");
  const [showMiniChatContent, setShowMiniChatContent] = useState(true);

  const [isSearchingSession, setIsSearchingSession] = useState(false);
  // Define a debounce function
  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  const containsOnlySpaces = (value) => value.trim() === "";
  const containsEmptyString = (value) => value === "";
  const handleSessionsSearch = (query) => {
    if (containsOnlySpaces(query) && !containsEmptyString(query)) {
      return;
    }

    if (containsEmptyString(query) && !allSessionsLoaded) {
      getAllSessions();
      setIsSearchingSession(true);
      return;
    }

    if (!containsOnlySpaces(query)) {
      setIsSearchingSession(true);
      HttpService.get(
        `${xaiBaseURL}/copilot/chats?q=${query}&page=1&limit=10`,
        {}
      )
        .then((res) => {
          setSessions(res.data.sessions);
          setAllSessionsLoaded(false);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setIsSearchingSession(false);
        });
    }
  };

  // Debounced version of the function
  const debouncedHandleSessionsSearch = debounce(handleSessionsSearch, 600);

  function extractTime(inputDateString) {
    const dateObject = new Date(inputDateString);

    if (isNaN(dateObject.getTime())) {
      return "Invalid date";
    }

    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();

    const ampm = hours >= 12 ? "PM" : "AM";

    const formattedHours = hours % 12 || 12;

    // Format the time
    const formattedTime = `${formattedHours
      .toString()
      .padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${ampm}`;

    return formattedTime;
  }

  const [patientInfo, setPatientInfo] = useState({
    patientId: null,
    patientName: null,
  });

  const getPatientId = (id) => {
    setPatientInfo({
      patientId: id,
      patientName: getPatient(id),
    });
  };

  const gotoReports = (id) => {
    history.push(`/report/${id}/summary`);
    onExpandClicked(false);
    setAddButtonsTransition(false);
  };

  return (
    <Box sx={{ height: "50px", display: "flex", justifyContent: "end" }}>
      <Box
        sx={{
          backgroundColor: "#f9f9f9",
          color: "000",
          width: open && !expanded ? "400px" : "0px",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Box
          sx={{
            height: "70px",
            display: "flex",
            justifyContent: "space-between",
            background: "#F9FAFB",
            borderBottom: "1px solid #EAECF0",
            borderLeft: "1px solid #EAECF0",
            px: 2,
            py: 1,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1.7,
            }}
          >
            <Box
              sx={{
                width: "130px",
                overflowX: "auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  color: "#000",
                  fontFamily: "Open Sans",
                  fontWeight: "700",
                  fontSize: "18px",
                  whiteSpace: "nowrap",
                }}
              >
                {headTitle ? headTitle : "New Chat"}
              </span>
            </Box>{" "}
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Button
                onClick={() => {
                  setChat([]);
                  setMiniSession(false);
                  setSessionId("");
                  setSelectedSessions("");
                  setShowSuggestion(true);
                  setNewChatClicked(true);
                  setSelectedButtonIndex(null);
                  setPrompt(null);
                  setShowMiniChatContent(true);
                  setHeadTitle("New Chat");
                }}
                startIcon={<NewChatIcon />}
                variant="contained"
                sx={{
                  minWidth: "max-content",
                  textTransform: "none",
                  background: "#FB6514",
                  color: "#fff",
                  "&:hover": {
                    background: "#FB6514",
                  },
                  fontFamily: "Open Sans",
                  fontWeight: "600",
                  fontSize: "14px",
                  borderRadius: "8px",
                }}
              >
                New Chat
              </Button>
              <Box
                onClick={() => {
                  onExpandClicked(true);
                  setAddButtonsTransition(true);
                  // onExpand(false);
                }}
                sx={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  background: "#fff",
                  border: "1px solid #D0D5DD",
                  cursor: "pointer",
                  borderRadius: "8px",
                  height: "36px",
                  py: 1,
                  px: 1.25,
                  color: "#fff",
                }}
              >
                <ExpandIcon />
              </Box>

              <Box
                onClick={closeCopilot}
                sx={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  background: "#fff",
                  border: "1px solid #D0D5DD",
                  cursor: "pointer",
                  borderRadius: "8px",
                  height: "36px",
                  p: 1,
                  color: "#fff",
                }}
              >
                <CloseIcon fontSize="small" sx={{ cursor: "pointer" }} />
              </Box>
            </Box>
          </Box>
        </Box>

        {(expandRightBar || !miniSession) && (
          <Box
            onClick={() => {
              setSelectedSessions("");
              setMiniSession(true);
              // toggleCopilotRightBar();
              setExpandRightBar(false);
              setShowMiniChatContent(false);
            }}
            sx={{
              background: "#101828",
              position: "absolute",
              right: 0,
              top: "94px",
              cursor: "pointer",
              borderRadius: "8px 0 0 8px",
              padding: "10px 12px 10px 12px",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              gap: 1.5,
              zIndex: 9,
            }}
          >
            <MenuIcon />
          </Box>
        )}
        <Box
          sx={{
            backgroundColor: "#fff",
            borderLeft: "1px solid #EAECF0",
            px: 2,
            pb: 2,
            flex: 1,
            overflowY: "auto",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/*  mini suggestions */}
          {showSuggestion && expandRightBar && (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                pt: "21px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 2,
                }}
              >
                <IconButton
                  onClick={() => {
                    handleShuffleSuggestion();
                    setSelectedButtonIndex(null);
                    setPrompt(null);
                  }}
                  sx={{
                    background: "#fff",
                    "&:hover": { background: "#fff" },
                  }}
                >
                  <RotateRightRoundedIcon
                    sx={{
                      color: "#475569",
                      transition: "transform 0.2s ease-in-out",
                      transform: `rotate(${rotation}deg)`,
                    }}
                  />
                </IconButton>
              </Box>
              <Box>
                <Box sx={{ pb: 2 }}>
                  <Typography
                    sx={{
                      fontFamily: "Open Sans",
                      fontSize: "24px",
                      fontWeight: "700",
                      color: "#EC4A0A",
                    }}
                  >
                    Hello, Welcome!
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Open Sans",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    {"Tell me what's on your mind, or pick a suggestion."}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    // display: "flex",
                    gap: 2,
                  }}
                >
                  {suggestions &&
                    suggestions.map((suggestion, index) => {
                      return (
                        <CopilotSuggestionCardMini
                          key={index}
                          data={suggestion}
                          onActionClick={getSuggestionMessage}
                          addTransition={addButtonsTransition}
                          // newChat={newChatClicked}
                          currentIndex={currentIndex}
                          getListIndex={getListIndex}
                          gradientFirstColor={
                            index === 0
                              ? "#ff6412"
                              : index === 1
                              ? "#ff801f"
                              : "#95a37c"
                          }
                          gradientSecondColor={
                            index === 0
                              ? "#ff801f"
                              : index === 1
                              ? "#95a37c"
                              : "#3ec0c9"
                          }
                          selected={
                            selectedButtonIndex &&
                            selectedButtonIndex.childIndex === index
                          }
                          selectedIndex={
                            selectedButtonIndex &&
                            selectedButtonIndex.childIndex === index
                              ? selectedButtonIndex.buttonIndex
                              : null
                          }
                          onClick={(buttonIndex) =>
                            handleSuggestionClick(index, buttonIndex)
                          }
                        />
                      );
                    })}
                </Box>
              </Box>
            </Box>
          )}

          {/* mini sessions list */}
          {!expandRightBar && miniSession && (
            <Box
              sx={{
                background: "#fff",
                width: "100%",
                position: "sticky",
                zIndex: 10,
                top: 0,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  mt: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <img src={mainlogo} alt="" style={{ width: "32px" }} />
                  <Typography
                    sx={{
                      fontFamily: "Open Sans",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Sessions
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    setSelectedSessions("");
                    setMiniSession(false);
                    // toggleCopilotRightBar();
                    setExpandRightBar(true);
                    setShowMiniChatContent(true);
                  }}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <SessionsColapseIcon />
                </Box>
              </Box>

              <Box sx={{ py: "24px", width: "100%" }}>
                <div
                  style={{
                    border: "1px solid #D0D5DD",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    overflow: "hidden",
                    padding: "0 12px",
                    gap: "12px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {isSearchingSession ? (
                      <CircularProgress size={20} />
                    ) : (
                      <SearchIcon />
                    )}
                  </div>
                  <input
                    onChange={(e) => {
                      debouncedHandleSessionsSearch(e.target.value);
                    }}
                    style={{
                      height: "40px",
                      width: "100%",
                      outline: "none",
                      border: "none",
                    }}
                    placeholder="Search Anything"
                  />
                </div>
              </Box>
            </Box>
          )}
          {!expandRightBar &&
            miniSession &&
            sessions?.map((sess, sesid) => (
              <Box
                sx={{
                  backgroundColor: "#f8fafc",
                  cursor: "pointer",
                  // p: 1,
                  border:
                    selectedSessions == sess?._id ? "2px solid #ff6413" : "",
                  mb: 1,
                  ":hover": { backgroundColor: "#f8fafc" },
                  display: "flex",
                  width: "100%",
                  borderRadius: "5px",
                }}
                key={sesid}
              >
                <Box sx={{ width: "100%" }}>
                  {tempSessionName.id !== sess?._id ? (
                    <Box
                      onClick={() => {
                        setMiniSession(false);
                        setShowMiniChatContent(true);
                        setHeadTitle(sess.name);
                      }}
                    >
                      <TruncateText
                        text={sess.name}
                        onClick={handleMiniSession}
                        id={sess?._id}
                        max={40}
                      />
                    </Box>
                  ) : (
                    <TextInputComponent
                      value={
                        tempSessionName.name ? tempSessionName.name : sess?.name
                      }
                      name="sessionname"
                      disable={tempSessionName.id !== sess?._id}
                      handleChange={(e) => {
                        setTempSessionName({
                          ...tempSessionName,
                          name: e.target.value,
                        });
                      }}
                    />
                  )}
                </Box>
                <Box sx={{ py: 1, pr: 1 }}>
                  {tempSessionName.id ? (
                    <Box>
                      {tempSessionName.id == sess._id && (
                        <Box
                          sx={{
                            textAlign: "center",
                            backgroundColor: "#ff6413",
                            borderRadius: "5px",
                            color: "#fff",
                            ml: "5px",
                            cursor: "pointer",
                            fontSize: "13px",
                            px: 1,
                            py: 1,
                            mt: "2px",
                          }}
                          onClick={() => {
                            handleUpdateSessionName(sesid);
                          }}
                        >
                          update
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <EditOutlinedIcon
                      fontSize="small"
                      sx={{ color: "#ff6413", ml: "5px", mt: "5px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setTempSessionName({
                          id: sess._id,
                          name: sess?.name,
                        });
                      }}
                    />
                  )}
                </Box>
              </Box>
            ))}
          {chat?.map((chatitem, chatiidx) =>
            chatitem?.type == "res" ? (
              <Box
                sx={{
                  display: showMiniChatContent ? "flex" : "none",
                  width: "100%",
                  mt: "20px",
                }}
              >
                <Box sx={{ mr: "5px" }}>
                  <Box
                    sx={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "17.5px",
                      border: "1px solid #ff6412",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={mainlogo} alt="logo" width="18px" />
                  </Box>
                </Box>
                <Box
                  key={chatiidx}
                  sx={{
                    mb: "2px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",

                    // minHeight: chat.length - 1 == chatiidx ? "70vh" : "",
                  }}
                >
                  {chatitem?.instruction && (
                    <Box
                      sx={{
                        display: "flex",
                        mt: 1,
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          p: 1,
                          textAlign: "center",
                          borderRadius: "10px 10px 10px 0px",
                          cursor: "pointer",
                          backgroundColor: "#f8fafc",
                          width: "100%",
                        }}
                        onClick={() => {
                          handleGoToPage(chatitem?.instruction);
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Box
                            sx={{
                              fontSize: "13px",
                              fontFamily: "Open Sans",
                              pt: 1,
                            }}
                          >
                            {"URL - " + chatitem?.instruction}
                          </Box>
                          <Box
                            sx={{
                              border: "2px solid #ff6413",
                              py: 1,
                              px: 2,
                              borderRadius: "7px",
                              ml: 3,
                            }}
                          >
                            <DisplayText
                              color={"#ff6413"}
                              content={"go to page"}
                              size={"sm"}
                              fontWeight={"600"}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {chatitem?.composite?.time &&
                    (chatitem?.composite?.blocks[0]?.type === "form" ? (
                      chatitem?.composite?.blocks[0]?.data.content
                        .slice(1)
                        .map((input, index) => {
                          const [label, key, value] = input;
                          return (
                            <Box
                              key={index + "" + key}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Box
                                key={index}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 1,
                                  mb: 2,
                                }}
                              >
                                <Typography sx={{ fontFamily: "Open Sans" }}>
                                  {label}
                                </Typography>
                                <DynamicInput
                                  key={index}
                                  onChange={handleInputChange}
                                  val={value}
                                  label={label}
                                  isTyping={isTyping}
                                  onTyping={getIsTyping}
                                  width="280px"
                                />
                              </Box>
                            </Box>
                          );
                        })
                    ) : chatitem?.composite?.blocks[0]?.type ===
                      "diagnostics_workflow_list" ? (
                      <Grid container spacing={2} sx={{ p: 1 }}>
                        {pworkflow &&
                          pworkflow?.map((tas, tasid) => {
                            return (
                              <Grid item xs={12} key={tasid}>
                                <TestItem
                                  fetchowndata={tas}
                                  course_data={tas}
                                  pti={""}
                                />
                              </Grid>
                            );
                          })}
                      </Grid>
                    ) : chatitem?.composite?.blocks[0]?.type ===
                      "patient_list" ? (
                      <DashboardTable
                        headers={[
                          "First Name",
                          "Last Name",
                          "Gender",
                          "Email",
                          "Phone",
                        ]}
                        hasId={true}
                        datas={
                          patientsList &&
                          patientsList?.map((item) => {
                            return [
                              item.resource.id,
                              item.resource.name[0].given[0],
                              item.resource.name[0].family,
                              item.resource.gender,
                              item.resource.telecom[0].value,
                              item.resource.telecom[1].value,
                            ];
                          })
                        }
                      />
                    ) : chatitem?.composite?.blocks[0]?.type === "task_list" ? (
                      <DashboardTable
                        headers={["Intent", "Description", "Status"]}
                        datas={
                          tasksList &&
                          tasksList?.map((item) => {
                            return [
                              item.resource.intent,
                              item.resource.description,
                              item.resource.status,
                            ];
                          })
                        }
                      />
                    ) : chatitem?.composite?.blocks[0]?.type ===
                      "meeting_list" ? (
                      <MeetingTable data={meetingList} />
                    ) : chatitem?.composite?.blocks[0]?.type ===
                      "encounter_list" ? (
                      <DashboardTable
                        headers={[
                          "Patient Profile",
                          "Status",
                          "Classification",
                          "Service Provided",
                          "Service Needed",
                        ]}
                        datas={
                          encounterList &&
                          encounterList.map((item) => {
                            return [
                              getPatient(
                                item.resource?.participant[1]?.individual?.id ||
                                  item.resource?.participant[0]?.individual?.id
                              ),
                              item?.resource?.status,
                              item?.resource?.class?.display,
                              item?.resource?.serviceType?.text,
                              item?.resource?.type[0]?.text,
                            ];
                          })
                        }
                      />
                    ) : chatitem?.composite?.blocks[0]?.type ===
                      "appointment_list" ? (
                      <DashboardTable
                        headers={[
                          "Practitioner",
                          "Description",
                          "Start date time",
                          "End date time",
                        ]}
                        datas={
                          appointmentsList &&
                          appointmentsList.map((appointment) => {
                            return [
                              getPatient(
                                appointment?.resource?.participant[0]?.actor?.id
                              ),
                              appointment?.resource?.description,
                              extractTime(appointment?.resource?.start),
                              extractTime(appointment?.resource?.end),
                            ];
                          })
                        }
                      />
                    ) : chatitem?.composite?.blocks[0]?.type ===
                      "new_patient" ? (
                      <DashboardTable
                        headers={[
                          "First Name",
                          "Last Name",
                          "Gender",
                          "Email",
                          "Phone",
                        ]}
                        datas={
                          patientCreated &&
                          patientCreated.map((patient) => {
                            return [
                              patient?.name[0]?.given[0],
                              patient?.name[0]?.family,
                              patient?.gender,
                              patient?.telecom[0]?.value,
                              patient?.telecom[1]?.value,
                            ];
                          })
                        }
                      />
                    ) : chatitem?.composite?.blocks[0]?.type ===
                      "pracitioner_list" ? (
                      <DashboardTable
                        headers={[
                          "First Name",
                          "Last Name",
                          "Gender",
                          "Email",
                          "Phone",
                        ]}
                        datas={
                          practitionersList &&
                          practitionersList.map((practitioner) => {
                            return [
                              practitioner?.resource?.name[0]?.given[0],
                              practitioner?.resource?.name[0]?.family,
                              practitioner?.resource?.gender,
                              practitioner?.resource?.telecom[0]?.value,
                              practitioner?.resource?.telecom[1]?.value,
                            ];
                          })
                        }
                      />
                    ) : chatitem?.composite?.blocks[0]?.type ===
                      "diagnostics_report_list" ? (
                      <DashboardTable
                        headers={["Diagnostics Name", "Description"]}
                        hasId={true}
                        datas={
                          reportsList &&
                          chatitem?.composite?.blocks[0]?.data.response.map(
                            (report) => {
                              return [
                                report.report_id,
                                report.name,
                                report.description,
                              ];
                            }
                          )
                        }
                        onClick={gotoReports}
                      />
                    ) : (
                      <Box sx={{ width: "100%" }}>
                        <ContentParser
                          data={chatitem.composite}
                          expanded={false}
                        />
                      </Box>
                    ))}
                  {/* {chatitem?.composite?.time && (
                    <Box>
                      <ContentParser
                        data={chatitem.composite}
                        expanded={false}
                      />
                    </Box>
                  )} */}
                  {chatitem?.exerpt && (
                    <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          mb: 1,
                          p: 1,
                          backgroundColor: "#f2f2f2",
                          borderRadius: "10px 10px 10px 0px",
                          width: "95%",
                        }}
                      >
                        {chatitem?.exerpt}
                      </Box>
                    </Box>
                  )}
                  <br />
                  {chatitem?.image && (
                    <Box
                      sx={{
                        mb: 1,
                        borderRadius: "5px",
                        width: "350px",
                        m: 0,
                      }}
                    >
                      <img src={chatitem?.image} style={{ width: "350px" }} />
                    </Box>
                  )}
                  {chatitem?.youtube && (
                    <Box
                      sx={{
                        mb: 1,
                        borderRadius: "5px",
                        display: "flex",
                      }}
                    >
                      <iframe
                        src={
                          "https://www.youtube-nocookie.com/embed/" +
                          chatitem?.youtube?.split("?v=")[1] +
                          "?rel=0"
                        }
                        style={{ width: "100%", height: "100%" }}
                        // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      ></iframe>
                    </Box>
                  )}
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  display: showMiniChatContent ? "flex" : "none",
                  flexDirection: "column",
                }}
              >
                <div ref={messagesEndRefSm} />
                <Box
                  sx={{ display: "flex", my: 2, width: "100%" }}
                  key={chatiidx}
                >
                  <Box sx={{ mr: "5px" }}>
                    <Box
                      sx={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "17.5px",
                        border: "1px solid #cbd5e1",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <PersonRoundedIcon
                        sx={{ color: "#FF6413", fontSize: "18px" }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      mb: "2px",
                      width: "100%",
                      background: "#f8fafc",
                      p: 1,
                      color: "#000",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography
                      sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
                    >
                      {chatitem?.question}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )
          )}
          {loading && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: "20px",
                width: "100%",
              }}
            >
              <Box sx={{ mr: "5px" }}>
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "17.5px",
                    border: "1px solid #ff6413",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={mainlogo} alt="logo" width="18px" />
                </Box>
              </Box>
              <DotLoading />
            </Box>
          )}
          <div ref={promptRefSm} />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "#fff",
            // mb: 4,
            // mx: 2.5,
            // pt: 1,
            // borderRadius: "0px 0px 10px 10px",
            // borderBottom: "1px solid #e2e8f0",
            // borderRight: "1px solid #e2e8f0",
            borderLeft: "1px solid #e2e8f0",
            gap: 2,
          }}
        >
          <Box sx={{ width: "100%", px: 2, pb: 2 }}>
            <TextInputComponent
              placeholder="Ask me Anything..."
              name={"prompttxt"}
              value={prompt || ""}
              handleChange={(e) => {
                setPrompt(e.target.value);
                if (isTyping) {
                  setIsTyping(false);
                }
              }}
              handleKeyPress={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  if (prompt !== null) {
                    handlePromptReq(1);
                  }
                }
              }}
              multiline={true}
              endIcon={
                isTyping ? (
                  <Button
                    size="small"
                    sx={{
                      background: "#ff6413",
                      "&:hover": {
                        background: "#ff6413",
                      },
                      color: "#fff",
                      cursor: "pointer",
                      textTransform: "none",
                      height: "36px",
                      borderRadius: "8px",
                      fontSize: "12px",
                      px: "14px",
                    }}
                    onClick={() => {
                      handlePromptReq(1);
                      setIsTyping(false);
                      setFormList({
                        data: {
                          withHeadings: true,
                          content: [],
                        },
                        prompt: "",
                      });
                    }}
                  >
                    Submit Form
                  </Button>
                ) : (
                  <Box
                    onClick={() => {
                      if (prompt !== null) {
                        handlePromptReq(1);
                      }
                    }}
                    sx={{
                      background: prompt ? "#FB6514" : "#FFD5B2",
                      height: "36px",
                      px: "8px",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      mr: "-10px",
                    }}
                  >
                    <SendIcon />
                  </Box>
                )
              }
            />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          height: "100vh",
          position: "relative",
          width:
            open && expanded
              ? leftSidebarState
                ? "calc(100vw - 73px)"
                : "calc(100vw - 251px)"
              : "0px",
          transition: "width .2s",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            position: "absolute",
            right: "0px",
            color: "#000",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            justifyContent: "start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#fff",
            }}
          ></Box>
          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            {/* copilot Header expanded */}
            <Box
              sx={{
                width: "100%",
                height: "70px",
                background: "#F9FAFB",
                borderBottom: "1px solid #EAECF0",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  maxWidth: !expandRightBar
                    ? `calc(100% - 400px)`
                    : `calc(100% )`,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "95%",
                    maxWidth: "1000px",
                    pl: !expandRightBar ? `0px` : `120px`,
                    transition: "all .1s",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      overflowX: "auto",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        color: "#000",
                        fontFamily: "Open Sans",
                        fontWeight: "700",
                        fontSize: "18px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {headTitle ? headTitle : "New Chat"}
                    </span>
                    {patientInfo.patientName && (
                      <Box
                        sx={{
                          background: "#344054",
                          borderRadius: "20px",
                          height: "40px",
                          pl: "15px",
                          pr: "5px",
                          ml: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Open Sans",
                            fontSize: "14px",
                            color: "#fff",
                          }}
                        >
                          {patientInfo?.patientName}
                        </Typography>
                        <IconButton
                          onClick={() => {
                            setPatientInfo({
                              patientId: null,
                              patientName: null,
                            });
                          }}
                        >
                          <CloseIcon color="#fff" size={"18"} />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 1, px: 2 }}
              >
                <Button
                  onClick={() => {
                    setChat([]);
                    setMiniSession(false);
                    setSessionId("");
                    setSelectedSessions("");
                    setShowSuggestion(true);
                    setNewChatClicked(true);
                    setSelectedButtonIndex(null);
                    setPrompt(null);
                    setHeadTitle("New Chat");
                  }}
                  startIcon={<NewChatIcon />}
                  variant="contained"
                  sx={{
                    minWidth: "max-content",
                    textTransform: "none",
                    background: "#FB6514",
                    color: "#fff",
                    "&:hover": {
                      background: "#FB6514",
                    },
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "14px",
                    borderRadius: "8px",
                  }}
                >
                  New Chat
                </Button>
                <Box
                  onClick={() => {
                    onExpandClicked(false);
                    setAddButtonsTransition(false);
                  }}
                  sx={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    background: "#fff",
                    border: "1px solid #D0D5DD",
                    cursor: "pointer",
                    borderRadius: "8px",
                    height: "36px",
                    py: 1,
                    px: 1.25,
                    color: "#fff",
                  }}
                >
                  <ColapseIcon />
                </Box>

                <Box
                  onClick={closeCopilot}
                  sx={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    background: "#fff",
                    border: "1px solid #D0D5DD",
                    cursor: "pointer",
                    borderRadius: "8px",
                    height: "36px",
                    p: 1,
                    color: "#fff",
                  }}
                >
                  <CloseIcon fontSize="small" sx={{ cursor: "pointer" }} />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "space-between",
              }}
            >
              {/* copilot section expanded */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  height: "calc(100vh - 70px)",
                  width: !expandRightBar ? `calc(100% - 400px)` : `calc(100%)`,
                  transition: "width .2s",
                  alignItems: "center",
                  overflow: "hidden",
                  // background: showSuggestion ? "#f4f6fc" : "#fff",
                  background: "#fff",
                }}
              >
                <Box
                  sx={{
                    width: "95%",
                    maxWidth: "1000px",
                    flexGrow: 1,
                    overflowY: "auto",
                    position: "relative",
                  }}
                >
                  {/* copilot action buttons container here */}
                  {showSuggestion && (
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "start",
                        gap: 3,
                        pt: "75px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "space-between",
                          mb: 2,
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img
                            src="https://www.gstatic.com/lamda/images/sparkle_resting_v2_darkmode_2bdb7df2724e450073ede.gif"
                            alt=""
                            style={{ width: "30px" }}
                          />
                        </Box>
                        <IconButton
                          onClick={() => {
                            handleShuffleSuggestion();
                            setSelectedButtonIndex(null);
                            setPrompt(null);
                          }}
                          sx={{
                            background: "#fff",
                            "&:hover": { background: "#fff" },
                          }}
                        >
                          <RotateRightRoundedIcon
                            sx={{
                              color: "#475569",
                              transition: "transform 0.2s ease-in-out",
                              transform: `rotate(${rotation}deg)`,
                            }}
                          />
                        </IconButton>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "2.5rem",
                            fontFamily: "Open Sans",
                            fontWeight: "bold",
                            color: "#1e293b",
                          }}
                        >
                          Hello, welcome!
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "Open Sans",
                            color: "#6b7280",
                          }}
                        >
                          {"Tell me what's on your mind, or pick a suggestion."}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          gap: 2,
                        }}
                      >
                        {suggestions &&
                          suggestions.map((suggestion, index) => {
                            return (
                              <CopilotSuggestionCard
                                key={index}
                                data={suggestion}
                                onActionClick={getSuggestionMessage}
                                addTransition={addButtonsTransition}
                                // newChat={newChatClicked}
                                currentIndex={currentIndex}
                                getListIndex={getListIndex}
                                gradientFirstColor={
                                  index === 0
                                    ? "#ff6412"
                                    : index === 1
                                    ? "#ff801f"
                                    : "#95a37c"
                                }
                                gradientSecondColor={
                                  index === 0
                                    ? "#ff801f"
                                    : index === 1
                                    ? "#95a37c"
                                    : "#3ec0c9"
                                }
                                selected={
                                  selectedButtonIndex &&
                                  selectedButtonIndex.childIndex === index
                                }
                                selectedIndex={
                                  selectedButtonIndex &&
                                  selectedButtonIndex.childIndex === index
                                    ? selectedButtonIndex.buttonIndex
                                    : null
                                }
                                onClick={(buttonIndex) =>
                                  handleSuggestionClick(index, buttonIndex)
                                }
                              />
                            );
                          })}
                      </Box>
                    </Box>
                  )}
                  {chat?.map((chatitem, chatiidx) =>
                    chatitem?.type == "res" ? (
                      <Box key={chatiidx} sx={{ display: "flex" }}>
                        <Box sx={{ mr: "5px" }}>
                          <Box
                            sx={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "17.5px",
                              border: "1px solid #ff6413",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img src={mainlogo} alt="logo" width="18px" />
                          </Box>
                        </Box>
                        <Box
                          key={chatiidx}
                          sx={{
                            mb: 1,
                            width: "100%",
                            minHeight:
                              chat.length - 1 == chatiidx ? "65vh" : "",
                          }}
                        >
                          {chatitem?.instruction && (
                            <Box sx={{ display: "flex" }}>
                              {/* <Box sx={{ width:"30px", height:"30px", mr:"5px" }}><img src={minixna} width={"100%"} /></Box> */}
                              <Box
                                sx={{
                                  p: 1,
                                  textAlign: "center",
                                  borderRadius: "10px 10px 10px 0px",
                                  cursor: "pointer",
                                  backgroundColor: "#f8fafc",
                                  width: "100%",
                                }}
                                onClick={() => {
                                  handleGoToPage(chatitem?.instruction);
                                }}
                              >
                                <Box sx={{ display: "flex" }}>
                                  <Box
                                    sx={{
                                      fontSize: "14px",
                                      fontFamily: "Open Sans",
                                      pt: 1,
                                    }}
                                  >
                                    {"URL - " + chatitem?.instruction}
                                  </Box>
                                  <Box
                                    sx={{
                                      border: "2px solid #ff6413",
                                      py: 1,
                                      px: 2,
                                      borderRadius: "7px",
                                      ml: 3,
                                    }}
                                  >
                                    <DisplayText
                                      color={"#ff6413"}
                                      content={"take me to the page"}
                                      size={"sm"}
                                      fontWeight={"600"}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          )}

                          {chatitem?.composite?.time &&
                            (chatitem?.composite?.blocks[0]?.type === "form" ? (
                              chatitem?.composite?.blocks[0]?.data.content
                                .slice(1)
                                .map((input, index) => {
                                  const [label, key, value] = input;
                                  return (
                                    <Box
                                      key={index + "" + key}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Box
                                        key={index}
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: 1,
                                          mb: 2,
                                        }}
                                      >
                                        <Typography
                                          sx={{ fontFamily: "Open Sans" }}
                                        >
                                          {label}
                                        </Typography>

                                        <DynamicInput
                                          key={index}
                                          onChange={handleInputChange}
                                          val={value}
                                          label={label}
                                          isTyping={isTyping}
                                          onTyping={getIsTyping}
                                          width="500px"
                                        />
                                      </Box>
                                    </Box>
                                  );
                                })
                            ) : chatitem?.composite?.blocks[0]?.type ===
                              "diagnostics_workflow_list" ? (
                              <Grid container spacing={3} sx={{ p: 1 }}>
                                {pworkflow &&
                                  pworkflow?.map((tas, tasid) => (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      lg={4}
                                      // xl={3}
                                      key={tasid}
                                    >
                                      <TestItem
                                        onClick={() => {
                                          onExpandClicked(false);
                                          setAddButtonsTransition(false);
                                        }}
                                        fetchowndata={tas}
                                        course_data={tas}
                                        pti={""}
                                      />
                                    </Grid>
                                  ))}
                              </Grid>
                            ) : chatitem?.composite?.blocks[0]?.type ===
                              "patient_list" ? (
                              <DashboardTable
                                headers={[
                                  "First Name",
                                  "Last Name",
                                  "Gender",
                                  "Email",
                                  "Phone",
                                ]}
                                hasId={true}
                                datas={
                                  patientsList &&
                                  patientsList?.map((item) => {
                                    return [
                                      item.resource.id,
                                      item.resource.name[0].given[0],
                                      item.resource.name[0].family,
                                      item.resource.gender,
                                      item.resource.telecom[0].value,
                                      item.resource.telecom[1].value,
                                    ];
                                  })
                                }
                                onClick={getPatientId}
                              />
                            ) : chatitem?.composite?.blocks[0]?.type ===
                              "task_list" ? (
                              <DashboardTable
                                headers={["Intent", "Description", "Status"]}
                                datas={
                                  tasksList &&
                                  tasksList?.map((item) => {
                                    return [
                                      item.resource.intent,
                                      item.resource.description,
                                      item.resource.status,
                                    ];
                                  })
                                }
                              />
                            ) : chatitem?.composite?.blocks[0]?.type ===
                              "meeting_list" ? (
                              <MeetingTable data={meetingList} />
                            ) : chatitem?.composite?.blocks[0]?.type ===
                              "encounter_list" ? (
                              <DashboardTable
                                headers={[
                                  "Patient Profile",
                                  "Status",
                                  "Classification",
                                  "Service Provided",
                                  "Service Needed",
                                ]}
                                datas={
                                  encounterList &&
                                  encounterList.map((item) => {
                                    return [
                                      getPatient(
                                        item.resource?.participant[1]
                                          ?.individual?.id ||
                                          item.resource?.participant[0]
                                            ?.individual?.id
                                      ),
                                      item?.resource?.status,
                                      item?.resource?.class?.display,
                                      item?.resource?.serviceType?.text,
                                      item?.resource?.type[0]?.text,
                                    ];
                                  })
                                }
                              />
                            ) : chatitem?.composite?.blocks[0]?.type ===
                              "appointment_list" ? (
                              <DashboardTable
                                headers={[
                                  "Practitioner",
                                  "Description",
                                  "Start date time",
                                  "End date time",
                                ]}
                                datas={
                                  appointmentsList &&
                                  appointmentsList.map((appointment) => {
                                    return [
                                      getPatient(
                                        appointment?.resource?.participant[0]
                                          ?.actor?.id
                                      ),
                                      appointment?.resource?.description,
                                      extractTime(appointment?.resource?.start),
                                      extractTime(appointment?.resource?.end),
                                    ];
                                  })
                                }
                              />
                            ) : chatitem?.composite?.blocks[0]?.type ===
                              "new_patient" ? (
                              <DashboardTable
                                headers={[
                                  "First Name",
                                  "Last Name",
                                  "Gender",
                                  "Email",
                                  "Phone",
                                ]}
                                datas={
                                  patientCreated &&
                                  patientCreated.map((patient) => {
                                    return [
                                      patient?.name[0]?.given[0],
                                      patient?.name[0]?.family,
                                      patient?.gender,
                                      patient?.telecom[0]?.value,
                                      patient?.telecom[1]?.value,
                                    ];
                                  })
                                }
                              />
                            ) : chatitem?.composite?.blocks[0]?.type ===
                              "pracitioner_list" ? (
                              <DashboardTable
                                headers={[
                                  "First Name",
                                  "Last Name",
                                  "Gender",
                                  "Email",
                                  "Phone",
                                ]}
                                datas={
                                  practitionersList &&
                                  practitionersList.map((practitioner) => {
                                    return [
                                      practitioner?.resource?.name[0]?.given[0],
                                      practitioner?.resource?.name[0]?.family,
                                      practitioner?.resource?.gender,
                                      practitioner?.resource?.telecom[0]?.value,
                                      practitioner?.resource?.telecom[1]?.value,
                                    ];
                                  })
                                }
                              />
                            ) : chatitem?.composite?.blocks[0]?.type ===
                              "diagnostics_report_list" ? (
                              <DashboardTable
                                headers={["Diagnostics Name", "Description"]}
                                hasId={true}
                                datas={
                                  reportsList &&
                                  chatitem?.composite?.blocks[0]?.data.response.map(
                                    (report) => {
                                      return [
                                        report.report_id,
                                        report.name,
                                        report.description,
                                      ];
                                    }
                                  )
                                }
                                onClick={gotoReports}
                              />
                            ) : (
                              <Box sx={{ width: "100%" }}>
                                <ContentParser
                                  data={chatitem.composite}
                                  expanded={true}
                                />
                              </Box>
                            ))}
                          {chatitem?.exerpt && (
                            <Box sx={{ display: "flex" }}>
                              <Box
                                sx={{
                                  mb: 1,
                                  p: 1,
                                  backgroundColor: "#f2f2f2",
                                  borderRadius: "5px",
                                  width: "95%",
                                }}
                              >
                                {chatitem?.exerpt}
                              </Box>
                            </Box>
                          )}
                          <br />
                          {(chatitem?.youtube || chatitem?.image) && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                backgroundColor: "#f2f2f2",
                                borderRadius: "5px",
                                width: "95%",
                                p: 1,
                                mb: 1,
                              }}
                            >
                              <Box sx={{ width: "49%" }}>
                                {chatitem?.image && (
                                  <Box>
                                    <img
                                      src={chatitem?.image}
                                      width={"100%"}
                                      height={"100%"}
                                    />
                                  </Box>
                                )}
                              </Box>
                              <Box sx={{ width: "49%" }}>
                                {chatitem?.youtube && (
                                  <Box sx={{ width: "100%" }}>
                                    <Box>
                                      <iframe
                                        src={
                                          "https://www.youtube-nocookie.com/embed/" +
                                          chatitem?.youtube?.split("?v=")[1] +
                                          "?rel=0"
                                        }
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      ></iframe>
                                    </Box>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                        <div ref={messagesEndRef} />
                        <Box sx={{ display: "flex", my: 2 }} key={chatiidx}>
                          <Box sx={{ mr: "5px" }}>
                            <Box
                              sx={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "17.5px",
                                border: "1px solid #cbd5e1",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <PersonRoundedIcon
                                sx={{ color: "#FF6413", fontSize: "18px" }}
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              mb: "2px",
                              width: "100%",
                              background: "#f8fafc",
                              py: 1,
                              px: 1.5,
                              color: "#000",
                              borderRadius: "5px",
                              fontSize: "18px",
                            }}
                          >
                            {chatitem?.question}
                          </Box>
                        </Box>
                      </Box>
                    )
                  )}
                  {loading && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: "20px",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ mr: "5px" }}>
                        <Box
                          sx={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "17.5px",
                            border: "1px solid #ff6413",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img src={mainlogo} alt="logo" width="18px" />
                        </Box>
                      </Box>
                      <DotLoading />
                    </Box>
                  )}
                  <div ref={promptRef} />
                </Box>
                <Box
                  sx={{
                    width: "95%",
                    maxWidth: "1000px",
                    py: 1.5,
                    display: "flex",
                    alignItems: "end",
                    position: "relative",
                  }}
                >
                  <TextField
                    ref={inputFieldRef}
                    id="filled-multiline-flexible"
                    multiline={true}
                    rows={rows ? 1 : ""}
                    name={"prompttxt"}
                    className={classes.root}
                    fullWidth
                    placeholder={
                      isTyping ? "Submit the form" : "Ask me Anything..."
                    }
                    value={prompt || ""}
                    onChange={(e) => {
                      setPrompt(e.target.value);
                      if (isTyping) {
                        setIsTyping(false);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        if (prompt !== null) {
                          handlePromptReq(2);
                        }
                      } else if (e.key == "Enter") {
                        setRows(false);
                      }
                    }}
                    size={"medium"}
                    style={{
                      backgroundColor: "#fff",
                      outline: "none",
                      borderRadius: "8px",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ height: "100%" }}>
                          {isTyping ? (
                            <Button
                              size="small"
                              sx={{
                                background: "#ff6413",
                                "&:hover": {
                                  background: "#ff6413",
                                },
                                color: "#fff",
                                cursor: "pointer",
                                textTransform: "none",
                                height: "36px",
                                borderRadius: "8px",
                                fontSize: "12px",
                                px: "14px",
                              }}
                              onClick={() => {
                                handlePromptReq(2);
                                setIsTyping(false);
                                setFormList({
                                  data: {
                                    withHeadings: true,
                                    content: [],
                                  },
                                  prompt: "",
                                });
                              }}
                            >
                              Submit Form
                            </Button>
                          ) : (
                            <Box
                              onClick={() => {
                                if (prompt !== null) {
                                  handlePromptReq(2);
                                }
                              }}
                              sx={{
                                background: prompt ? "#FB6514" : "#FFD5B2",
                                height: "42px",
                                px: "12px",
                                borderRadius: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <SendIcon />
                            </Box>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>

              {/* sessions list section */}
              <Box
                sx={{
                  position: "relative",
                  height: "calc(100vh - 70px)",
                  width: expandRightBar ? "0px" : "auto",
                  borderLeft: "1px solid #f1f5f9",
                  pt: "24px",
                }}
              >
                {expandRightBar && (
                  <Box
                    onClick={() => {
                      setExpandRightBar(false);
                    }}
                    sx={{
                      background: "#101828",
                      position: "absolute",
                      right: 0,
                      top: "24px",
                      cursor: "pointer",
                      borderRadius: "8px 0 0 8px",
                      padding: "10px 16px 10px 12px",
                      color: "#fff",

                      display: "flex",
                      alignItems: "center",
                      gap: 1.5,
                    }}
                  >
                    <MenuIcon /> <Typography>Sessions</Typography>
                  </Box>
                )}

                <Box
                  sx={{
                    width: !expandRightBar ? "400px" : "0px",
                    overflow: "hidden",
                    transition: "width .2s",
                    maxHeight: "100vh",
                    background: "#fff",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      px: 2,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <img src={mainlogo} alt="" style={{ width: "32px" }} />
                      <Typography
                        sx={{
                          fontFamily: "Open Sans",
                          fontWeight: "600",
                          fontSize: "14px",
                        }}
                      >
                        Sessions
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setExpandRightBar(true);
                      }}
                    >
                      <SessionsColapseIcon />
                    </Box>
                  </Box>
                  <Box sx={{ px: 2, py: "24px" }}>
                    <div
                      style={{
                        border: "1px solid #D0D5DD",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        overflow: "hidden",
                        padding: "0 12px",
                        gap: "12px",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {isSearchingSession ? (
                          <CircularProgress size={20} />
                        ) : (
                          <SearchIcon />
                        )}
                      </div>
                      <input
                        onChange={(e) => {
                          debouncedHandleSessionsSearch(e.target.value);
                        }}
                        style={{
                          height: "40px",
                          width: "100%",
                          outline: "none",
                          border: "none",
                        }}
                        placeholder="Search Anything"
                      />
                    </div>
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 1,
                      flexDirection: "column",
                      gap: 1,
                      overflowY: "auto",
                      px: 2,
                      width: !expandRightBar ? "400px" : "0px",
                      display: !expandRightBar ? "flex" : "hidden",
                    }}
                  >
                    {sessions?.map((sess, sesid) => (
                      <Box
                        sx={{
                          width: "100%",
                          borderRadius: "5px",
                          cursor: "pointer",
                          background:
                            selectedSessions == sess?._id
                              ? "#FB6514"
                              : "#F2F4F7",
                          color:
                            selectedSessions == sess?._id ? "#fff" : "#000",

                          display: "flex",
                        }}
                        key={sesid}
                      >
                        <Box
                          onClick={() => {
                            setHeadTitle(sess.name);
                          }}
                          sx={{
                            width: "90%",
                            display: "flex",
                            alignItems: "center",
                            pl: 1,
                          }}
                        >
                          {tempSessionName.id !== sess?._id ? (
                            <TruncateText
                              text={sess.name}
                              onClick={handleMiniSession}
                              id={sess?._id}
                              max={40}
                            />
                          ) : (
                            <TextInputComponent
                              value={
                                tempSessionName.name
                                  ? tempSessionName.name
                                  : sess?.name
                              }
                              name="sessionname"
                              disable={tempSessionName.id !== sess?._id}
                              handleChange={(e) => {
                                setTempSessionName({
                                  ...tempSessionName,
                                  name: e.target.value,
                                });
                              }}
                            />
                          )}
                        </Box>
                        <Box sx={{ py: 1, pr: 1 }}>
                          {tempSessionName.id ? (
                            <Box>
                              {tempSessionName.id == sess._id && (
                                <Box
                                  sx={{
                                    textAlign: "center",
                                    backgroundColor: "#ff6413",
                                    borderRadius: "5px",
                                    color: "#fff",
                                    ml: "5px",
                                    cursor: "pointer",
                                    fontSize: "13px",
                                    px: 1,
                                    py: 1,
                                    mt: "2px",
                                  }}
                                  onClick={() => {
                                    handleUpdateSessionName(sesid);
                                  }}
                                >
                                  update
                                </Box>
                              )}
                            </Box>
                          ) : (
                            <EditOutlinedIcon
                              fontSize="small"
                              sx={{ color: "#ccc", ml: "5px" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setTempSessionName({
                                  id: sess._id,
                                  name: sess?.name,
                                });
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {!open && (
        <Box sx={{ height: "40px" }}>
          <Box
            onClick={() => {
              openCopilot();
              getSuggestionList();
              fetchPatientsResource()
                .then((res) => {
                  // setAllPatients(res?.data?.entry);
                  setAllPatients(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    res.data?.entry?.map((pi: any) => ({
                      value: pi?.resource?.id,
                      name:
                        pi?.resource?.name[0]?.given[0] +
                        " " +
                        pi?.resource?.name[0]?.family,
                    }))
                  );
                })
                .catch((err) => console.log(err));
            }}
            sx={{
              backgroundColor: "#ff6413",
              color: "#fff",
              ":hover": { backgroundColor: "#ff6413" },

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
              borderRadius: " 8px 0 0 8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                p: "10px 16px",

                cursor: "pointer",
              }}
            >
              <img
                src={duplogo}
                height={"16px"}
                style={{ cursor: "pointer" }}
              />
              <Typography
                sx={{
                  cursor: "pointer",
                  fontFamily: "Open Sans",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Copilot
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

AutoPilotPage.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  rows: PropTypes.number,
  borderRD: PropTypes.bool,
  onToggle: PropTypes.func,
  onExpand: PropTypes.func,
  leftSidebarState: PropTypes.any,
  expanded: PropTypes.any,
  onExpandClicked: PropTypes.func,
};

export default AutoPilotPage;
