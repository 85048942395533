import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useEffect } from "react";
import TextInputComponent from "../../localcomponents/TextInput";
import EditIcon from "../../localcomponents/Icons/Edit";

import { updatePatientsResource } from "../../localservices/patient.service";

const DashboardTable = ({
  headers = [],
  datas = [[]],
  showDate = false,
  onClick = () => {},
  maxHeight = "auto",
  hasId = false,
  isRowClickable = false,
  isPatient = false,
}) => {
  const [textColor, setTextColor] = useState("#000");
  const [rowId, setRowId] = useState(0);
  const [isEditable, setIsEditable] = useState(false);
  const [patientData, setPatientData] = useState({});

  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      currentDate
    );
    const formattedDate = `${day} ${month}`;

    const year = currentDate.getFullYear();

    return { formattedDate, year };
  };

  const handlePatientEditable = () => {
    if (!isEditable) setIsEditable(true);
    else {
      const tempnewpatient = {
        resourceType: "Patient",
        active: true,
        id: patientData["ID"],
        name: [
          {
            given: [patientData["First Name"]],
            family: patientData["Last name"],
          },
        ],
        gender: patientData["Gender"],
        telecom: [
          {
            system: "email",
            value: patientData["Email"],
          },
          {
            system: "phone",
            value: patientData["Phone Number"],
          },
        ],
        address: [
          {
            use: "home",
            type: "both",
            line: [patientData["street"]],
            city: patientData["City"],
            state: patientData["State"],
          },
        ],
      };

      updatePatientsResource(patientData["ID"], tempnewpatient)
        .then((patres) => {
          setIsEditable(false);
        })
        .catch((e) => {
          console.log("err", e);
        });
    }
  };

  useEffect(() => {
    let pdata = {};
    if (isPatient && datas.length > 1) {
      for (let x = 0; x < datas.length; x++) {
        let k = datas[x][0];
        let v = datas[x][1];
        pdata[k + ""] = v;
      }
      setPatientData(pdata);
    }
  }, []);

  // console.log(patientData, ">>");

  return (
    <TableContainer
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        background: "#fff",
        borderRadius: "10px",
        maxHeight: maxHeight,
        px: 1,
        pb: 1,
      }}
    >
      <Table sx={{ position: "relative" }}>
        <TableHead
          sx={{
            borderBottom: "1px solid #EAECF0",
            borderTop: "1px solid #EAECF0",
            position: "sticky",
            top: 0,
            left: 0,
            background: "#fff",
          }}
        >
          <TableRow sx={{ height: "40px" }}>
            {headers.map((header, index) => {
              return (
                <TableCell key={index} sx={{ py: 0 }}>
                  <Typography
                    fontWeight={"600"}
                    sx={{
                      cursor: "pointer",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      color: "#000",
                      fontWeight: "600",
                    }}
                  >
                    {header}
                  </Typography>
                </TableCell>
              );
            })}
            {isPatient && (
              <TableCell>
                {isEditable ? (
                  <Typography
                    fontWeight={"600"}
                    sx={{
                      cursor: "pointer",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      color: "#1E5267",
                      fontWeight: "600",
                    }}
                    onClick={() => {
                      handlePatientEditable();
                    }}
                  >
                    Update
                  </Typography>
                ) : (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePatientEditable();
                    }}
                  >
                    <EditIcon color={"#98A2B3"} />
                  </IconButton>
                )}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody sx={{ overflowY: "auto" }}>
          {showDate && (
            <>
              <TableRow
                sx={{
                  background: "#F2F4F7",
                  height: "40px",
                  width: "100%",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  //   pl: 1,
                  position: "absolute",
                  top: "0px",
                }}
              >
                <TableCell sx={{ border: "none" }}>
                  <Typography
                    sx={{
                      fontFamily: "Open Sans",
                      fontSize: "16px",
                      fontWeight: "700",
                      color: "#000",
                    }}
                  >
                    {getCurrentDate().formattedDate}
                    <span
                      style={{
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#000",
                      }}
                    >
                      {getCurrentDate().year}
                    </span>
                  </Typography>
                </TableCell>
              </TableRow>
              {/* <TableRow sx={{ height: "50px", border: "none" }}>
                <TableCell sx={{ border: "none" }}></TableCell>
              </TableRow> */}
            </>
          )}
          {!isPatient
            ? datas &&
              datas?.map((data, id) => (
                <TableRow
                  key={id}
                  onMouseOver={() => {
                    setTextColor("#fff");
                    setRowId(id);
                  }}
                  onMouseOut={() => {
                    setTextColor("#000");
                  }}
                  sx={{
                    "&:hover": {
                      background: "#1E5267",
                    },
                    transition: "background-color 0.1s ease",
                    overflow: "hidden",
                    height: "40px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (isRowClickable) {
                      onClick(id);
                    }
                  }}
                >
                  {data.map((item, index) => {
                    if (hasId) {
                      return (
                        index !== 0 && (
                          <TableCell
                            onClick={() => {
                              onClick(data[0]);
                            }}
                            key={index}
                            sx={{
                              minWidth: "150px",
                              borderBottom: "none",
                              px: "16px",
                              borderRadius:
                                index === 0
                                  ? "8px 0 0 8px"
                                  : index === data.length - 1
                                  ? "0 8px 8px 0"
                                  : "0",
                            }}
                          >
                            <Box sx={{}}>
                              <Typography
                                sx={{
                                  fontFamily: "Open Sans",
                                  fontSize: "14px",
                                  color: rowId === id ? textColor : "#000",
                                }}
                              >
                                {item}
                              </Typography>
                            </Box>
                          </TableCell>
                        )
                      );
                    } else {
                      return (
                        <TableCell
                          key={index}
                          sx={{
                            // borderRight: "1px solid #e2e8f0",
                            minWidth: "150px",
                            borderBottom: "none",
                            px: "16px",
                            borderRadius:
                              index === 0
                                ? "8px 0 0 8px"
                                : index === data.length - 1
                                ? "0 8px 8px 0"
                                : "0",
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontFamily: "Open Sans",
                                fontSize: "14px",
                                color: rowId === id ? textColor : "#000",
                              }}
                            >
                              {item}
                            </Typography>
                          </Box>
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))
            : datas &&
              datas?.map((edata, eid) => (
                <TableRow
                  key={eid}
                  sx={{
                    overflow: "hidden",
                    height: "40px",
                    "&:hover": {
                      background: isEditable ? "" : "#1E5267",
                    },
                  }}
                  onMouseOver={() => {
                    setTextColor("#fff");
                    setRowId(eid);
                  }}
                  onMouseOut={() => {
                    setTextColor("#000");
                  }}
                >
                  <TableCell
                    sx={{
                      borderBottom: "none",
                      px: "16px",
                      borderRadius: "8px",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        sx={{
                          fontFamily: "Open Sans",
                          fontSize: "14px",
                          pt: 1,
                          minWidth: "150px",
                          color:
                            rowId === eid && !isEditable ? textColor : "#000",
                        }}
                      >
                        {edata[0]}
                      </Typography>
                      <Box
                        sx={{
                          borderBottom: isEditable ? "1px solid #000" : "",
                          flexGrow: 1,
                        }}
                      >
                        {isEditable ? (
                          <TextInputComponent
                            size="small"
                            name={edata[0] + ""}
                            value={patientData[edata[0] + ""]}
                            handleChange={(e) => {
                              setPatientData({
                                ...patientData,
                                [edata[0] + ""]: e.target.value,
                              });
                            }}
                            disabled={edata[0] == "ID"}
                          />
                        ) : (
                          <Box>
                            <Typography
                              sx={{
                                fontFamily: "Open Sans",
                                fontSize: "14px",
                                pt: 1,
                                minWidth: "150px",
                                color: rowId === eid ? textColor : "#000",
                              }}
                            >
                              {patientData[edata[0] + ""]}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashboardTable;
