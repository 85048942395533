import React from "react";

const SessionsColapseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5 21L19 21C20.1046 21 21 20.1046 21 19L21 5C21 3.89543 20.1046 3 19 3L5 3C3.89543 3 3 3.89543 3 5L3 19C3 20.1046 3.89543 21 5 21Z"
        stroke="#020813"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 21L15 3M8 9L11 12L8 15"
        stroke="#020813"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SessionsColapseIcon;
