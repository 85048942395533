import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";

import "../index.css";

const CopilotSuggestionCardMini = ({
  data,
  onActionClick,
  addTransition,
  newChat,
  getListIndex,
  currentIndex,
  selected,
  selectedIndex,
  onClick,
  gradientFirstColor,
  gradientSecondColor,
}) => {
  const [newIndex, setNewIndex] = useState(-1);
  useEffect(() => {
    getListIndex(data.items.length);
  }, []);

  useEffect(() => {
    if (newIndex > data.items.length) {
      setNewIndex(0);
    } else {
      setNewIndex((prev) => (prev + 1) % data.items.length);
    }
  }, [currentIndex]);

  return (
    <Box
      sx={{
        height: "166px",
        width: "100%",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        // gap: 1,
        overflow: "hidden",
      }}
    >
      {/* <Typography
        sx={{
          fontSize: "1.2rem",
          fontFamily: "Sfbold",
          fontWeight: "bold",
          letterSpacing: 1,
          background: `linear-gradient(45deg, ${gradientFirstColor}, ${gradientSecondColor})`,
          WebkitBackgroundClip: "text",
          color: "transparent", // Hide the original text
          display: "inline-block",
        }}
      >
        {data.name}
      </Typography> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: 1,
          transform: addTransition ? "translateY(0)" : "translateY(10px)",
          transition: `transform 0.05s ease-in`,
        }}
      >
        {[
          ...data.items.slice(newIndex, newIndex + 3),
          ...data.items.slice(
            0,
            Math.max(0, 3 - (data.items.length - newIndex))
          ),
        ].map((action, index) => (
          <Button
            key={index}
            onClick={() => {
              onClick(index);
              const description =
                selected && selectedIndex === index ? "" : action.description;
              onActionClick(description);
            }}
            sx={{
              //   height: "35px",
              borderRadius: "8px",
              border:
                selected && selectedIndex === index
                  ? "1px solid #60a5fa"
                  : "1px solid #D0D5DD",
              background:
                selected && selectedIndex === index ? "#60a5fa" : "#F9FAFB",
              color: selected && selectedIndex === index ? "#fff" : "#020813",
              "&:hover": {
                background:
                  selected && selectedIndex === index ? "#60a5fa" : "#F9FAFB",
                color: selected && selectedIndex === index ? "#fff" : "#475569",
              },

              fontFamily: "Open Sans",
              fontSize: "14px",
              fontWeight: "600",
              textTransform: "none",
              p: "10px 16px",

              //   transform: addTransition
              //     ? "translateY(0)"
              //     : `translateY(${(index + 1) * 50}px)`,
              //   transition: `transform 0.2s ease-in, background 0.05s ease-in, color 0.05s ease-in`,
            }}
          >
            {action.title.length > 23
              ? action.title.slice(0, 22) + "..."
              : action.title}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default CopilotSuggestionCardMini;
