import React from "react";

const EditIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_165_20492)">
        <path
          d="M9.16656 3.33333H3.33323C2.8912 3.33333 2.46728 3.50892 2.15472 3.82148C1.84216 4.13404 1.66656 4.55797 1.66656 4.99999V16.6667C1.66656 17.1087 1.84216 17.5326 2.15472 17.8452C2.46728 18.1577 2.8912 18.3333 3.33323 18.3333H14.9999C15.4419 18.3333 15.8658 18.1577 16.1784 17.8452C16.491 17.5326 16.6666 17.1087 16.6666 16.6667V10.8333"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.4166 2.08333C15.7481 1.75181 16.1977 1.56557 16.6666 1.56557C17.1354 1.56557 17.585 1.75181 17.9166 2.08333C18.2481 2.41485 18.4343 2.86449 18.4343 3.33333C18.4343 3.80217 18.2481 4.25181 17.9166 4.58333L9.9999 12.5L6.66656 13.3333L7.4999 10L15.4166 2.08333Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_165_20492">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditIcon;
