import React from "react";

const VerticalDotsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.99996 10.8337C10.4602 10.8337 10.8333 10.4606 10.8333 10.0003C10.8333 9.54009 10.4602 9.16699 9.99996 9.16699C9.53972 9.16699 9.16663 9.54009 9.16663 10.0003C9.16663 10.4606 9.53972 10.8337 9.99996 10.8337Z"
        stroke="#1B3046"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99996 4.99967C10.4602 4.99967 10.8333 4.62658 10.8333 4.16634C10.8333 3.7061 10.4602 3.33301 9.99996 3.33301C9.53972 3.33301 9.16663 3.7061 9.16663 4.16634C9.16663 4.62658 9.53972 4.99967 9.99996 4.99967Z"
        stroke="#1B3046"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99996 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8333C10.8333 15.3731 10.4602 15 9.99996 15C9.53972 15 9.16663 15.3731 9.16663 15.8333C9.16663 16.2936 9.53972 16.6667 9.99996 16.6667Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VerticalDotsIcon;
