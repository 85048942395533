import HttpService from "./http.service";
import {
  patientURL,
  fhirURL,
  drxnaUrl,
  userContentURL,
  xaiBaseURL,
} from "./url.service";

export const getAllPatients = () => {
  return HttpService.get(patientURL, {});
};

export const createPatientResource = (patientobj) => {
  const org = localStorage.getItem("orgid");
  return HttpService.post(fhirURL + "Patient?org_id=" + org, patientobj);
};

export const getPatientById = (pi) => {
  const org = localStorage.getItem("orgid");
  return HttpService.get(fhirURL + "Patient?_id=" + pi + "&org_id=" + org, {});
};

export const createPractitionerResource = (patientobj) => {
  const org = localStorage.getItem("orgid");
  return HttpService.post(fhirURL + "Practitioner?org_id=" + org, patientobj);
};

export const fetchPatientsResource = () => {
  const org = localStorage.getItem("orgid");
  return HttpService.get(fhirURL + "Patient?active=true&org_id=" + org, {});
  // return HttpService.get(fhirURL + "Patient?org_id=" + org, {});
};

export const deletePatientsResource = (patid) => {
  const org = localStorage.getItem("orgid");
  return HttpService.remove(
    fhirURL + "Patient/" + patid + "?org_id=" + org,
    {}
  );
};

export const deletePractitionersResource = (patid) => {
  const org = localStorage.getItem("orgid");
  return HttpService.remove(
    fhirURL + "Practitioner/" + patid + "?org_id=" + org,
    {}
  );
};

export const updatePatientsResource = (patid, patdata) => {
  const org = localStorage.getItem("orgid");
  return HttpService.put(
    fhirURL + "Patient/" + patid + "?org_id=" + org,
    patdata
  );
};

export const updatePractitionersResource = (patid, patdata) => {
  const org = localStorage.getItem("orgid");
  return HttpService.put(
    fhirURL + "Practitioner/" + patid + "?org_id=" + org,
    patdata
  );
};

export const fetchPractitionersResource = () => {
  const org = localStorage.getItem("orgid");
  return HttpService.get(fhirURL + "Practitioner?org_id=" + org, {});
};

export const createMeetingRequest = (data) => {
  return HttpService.post(drxnaUrl + "/vtb/", data);
};

export const getListOfMeetings = () => {
  return HttpService.get(drxnaUrl + "/vtb/", {});
};

export const removeMeetingLink = (id) => {
  return HttpService.remove(drxnaUrl + "/vtb/" + id, {});
};

export const getPatientWforders = (id) => {
  return HttpService.get(drxnaUrl + "/patient/" + id + "/order");
};

export const binaryFileUpload = (payload) => {
  const org = localStorage.getItem("orgid");
  return HttpService.post(`${fhirURL}Binary?org_id=${org}`, payload);
};

export const patientFileAttachment = (payload) => {
  const org = localStorage.getItem("orgid");
  return HttpService.post(`${fhirURL}DocumentReference?org_id=${org}`, payload);
};

export const attachContentToBasic = (payload) => {
  const org = localStorage.getItem("orgid");
  return HttpService.post(`${fhirURL}Basic?org_id=${org}`, payload);
};

export const getDocumentBasic = (id) => {
  const org = localStorage.getItem("orgid");
  return HttpService.get(
    `${fhirURL}Basic?org_id=${org}&identifier=https://drxna.com/identifier|${id}`
  );
};

export const getPatientAttachments = (id) => {
  const org = localStorage.getItem("orgid");
  return HttpService.get(
    `${fhirURL}DocumentReference?patient=${id}&org_id=${org}`,
    {}
  );
};

export const getFileData = (url) => {
  const org = localStorage.getItem("orgid");
  return HttpService.get(`${fhirURL}${url}?org_id=${org}`, {});
};

export const addTimelineComment = (payload) => {
  const org = localStorage.getItem("orgid");
  return HttpService.post(`${fhirURL}Communication?org_id=${org}`, payload);
};

export const getAllTimelines = (id) => {
  const org = localStorage.getItem("orgid");
  return HttpService.get(
    `${fhirURL}Communication?org_id=${org}&subject=Patient/${id}`,
    {}
  );
};

export const deleteTimelineComment = (id) => {
  const org = localStorage.getItem("orgid");
  return HttpService.remove(`${fhirURL}Communication/${id}?org_id=${org}`, {});
};

export const addTimelineMedia = (payload) => {
  return HttpService.post(`${fhirURL}Media?org_id=${org}`, payload);
};

export const getAllMedias = (id) => {
  const org = localStorage.getItem("orgid");
  return HttpService.get(
    `${fhirURL}Media?org_id=${org}&subject=Patient/${id}&_count=100`,
    {}
  );
};

export const deleteTimelineMedia = (id) => {
  const org = localStorage.getItem("orgid");
  return HttpService.remove(`${fhirURL}Media/${id}?org_id=${org}`, {});
};

export const uploadMedia = (upimg) => {
  const fd = new FormData();
  fd.append("files", upimg);
  return HttpService.multi_part_post(userContentURL + "/media", fd);
};

export const uploadToOCR = (file) => {
  const fd = new FormData();
  fd.append("file", file);

  return HttpService.multi_part_post(
    xaiBaseURL + "/utils/pdf/extract-text",
    fd
  );
};

export const getOcrText = (id) => {
  return HttpService.get(xaiBaseURL + "/queue/queue/" + id, {});
};
