import HttpService from "./http.service";
import {
  loginURL,
  registerURL,
  inviteURL,
  verifyAccountURL,
  userURL,
} from "./url.service";

export const login = (data) => {
  return HttpService.post(loginURL, data);
};

export const signup = (data) => {
  return HttpService.post(registerURL, data);
};

export const requestInvite = (data) => {
  return HttpService.post(inviteURL, data);
};

export const verifyAccountRequest = (token) => {
  return HttpService.get(verifyAccountURL + "/" + token, {});
};

export const registerUser = (data) => {
  return HttpService.post(registerURL, data);
};

export const getGoogleToken = () => {
  return HttpService.get("https://xbio.xnacloud.com/auth/google/token", {});
};

export const getCopilotRequest = (data) => {
  return HttpService.post("https://xai.xnacloud.com/copilot/chat", data);
  // return HttpService.post("http://143.244.185.107:4000/copilot/chat", data);
};

export const getUser = (id) => {
  return HttpService.get(userURL + "?user_id=" + id, {});
};

// "https://apidrxna.xnacloud.com/fhir/";
// http://143.244.185.107:5000/
// const xaiURL = "https://xai.xnacloud.com/";
export const getChatSessions = (page = 1) => {
  return HttpService.get(
    "https://xai.xnacloud.com/copilot/chats?page=" +
      page +
      "&limit=10&ascending=false",
    {}
  );
};

export const getSessionMessages = (page = 1, sid) => {
  return HttpService.get(
    "https://xai.xnacloud.com/copilot/chat/" +
      sid +
      "?page=" +
      page +
      "&limit=10",
    {}
  );
};

export const updateSessionName = (id, name) => {
  return HttpService.put("https://xai.xnacloud.com/copilot/chat/" + id, {
    session_name: name,
  });
};

export const getCurrentUserDetail = (uid = localStorage.getItem("uid")) => {
  return HttpService.get(
    "https://xmi.xnacloud.com/get_user?user_id=" + uid,
    {}
  );
};

export const updateUser = (data) => {
  return HttpService.post("https://xmi.xnacloud.com/update_user", data);
};

export const updateUserPassword = (data) => {
  return HttpService.post(
    "https://xmi.xnacloud.com/update_user_password",
    data
  );
};
