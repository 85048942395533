export const baseURL = process.env.REACT_APP_BASE_URL;

export const contentURL = process.env.REACT_APP_CONTENT_URL;

// auth
export const loginURL = baseURL + "login";
export const registerURL = baseURL + "register";
// export const registerURL = xmiURL + 'register';
export const inviteURL = baseURL + "request_invite";
export const verifyAccountURL = baseURL + "verify_account";
export const userURL = baseURL + "get_user";

export const userContentURL = contentURL + "user";

// appointment
export const appointmentURL = baseURL + "appointments";
export const appointmentDatesURL = appointmentURL + "/dates";

// patient
export const patientURL = baseURL + "patients";

export const drxnaUrl = process.env.REACT_APP_DRXNA_URL;

export const fhirURL = drxnaUrl + "/fhir/";

export const xaiBaseURL = process.env.REACT_APP_XAI_URL;

export const suggestionUrl = xaiBaseURL + "/copilot/chat/suggestions";
export const courseContentURL = contentURL + "content";
export const topicURL = contentURL + "topic";

export const deleteLotURL = baseURL + "samplelot/delete_lot";
export const updateLotURL = baseURL + "samplelot/update_lot";
export const removeSampleURL = baseURL + "remove_sample";
export const getASampleURL = baseURL + "get_sample";
export const updateSampleURL = baseURL + "update_sample";

export const shipmentURL = drxnaUrl + "/shipment";

export const xflowURL = process.env.REACT_APP_XFLOW_URL;

export const meetURL = process.env.REACT_APP_MEET_URL;